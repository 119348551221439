import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { getAccessLogic } from "src/data/user/logic/accessLogic"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import ConfirmDialog from "src/ui/Dialog/ConfirmDialog"
import { MText } from "src/ui/MText"

export function UpgradeBlockerDialog({
  open,
  onClose,
  onConfirm,
}: {
  open: boolean
  onClose: () => void
  onConfirm?: () => void
}) {
  const { navigate } = useRouter()

  const { t, langKeys } = useTranslate()
  const { org } = useOrganization()
  const isOwner = getAccessLogic({ role: org.user_role }).hasOwnerAccess

  if (!isOwner) {
    return (
      <ConfirmDialog
        title={t(langKeys.subscription_upgrade_blocker_title)}
        confirmLabel={t(langKeys.ok)}
        onClose={onClose}
        onConfirm={onConfirm || onClose}
        open={open}
        showCancel={false}
        confirmButtonProps={{ style: { minWidth: "20ch" } }}
      >
        <MText variant="body">
          {t(langKeys.subscription_upgrade_blocker_title_non_owner)}
        </MText>
      </ConfirmDialog>
    )
  }

  return (
    <ConfirmDialog
      confirmLabel={t(langKeys.subscription_dialog_positive_action)}
      title={t(langKeys.subscription_upgrade_blocker_title)}
      open={open}
      onClose={onClose}
      onConfirm={onConfirm || (() => navigate(Routes.ChangePlan.location()))}
      cancelLabel={t(langKeys.close)}
    >
      <MText variant="body">
        {t(langKeys.subscription_upgrade_blocker_body)}
      </MText>
    </ConfirmDialog>
  )
}
